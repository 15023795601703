// GENERATE BY ./scripts/generate.ts
// DON NOT EDIT IT MANUALLY

import * as React from 'react';
import { BonusFilled as BonusFilledSvg } from 'icons-svg';
import type { IconReactProps } from '../components/IconReact';
import IconReact from '../components/IconReact';

const BonusFilled = (
  props: IconReactProps,
  ref: React.Ref<HTMLSpanElement>,
): React.ReactElement => (
  <IconReact {...props} ref={ref} icon={BonusFilledSvg} />
);

BonusFilled.displayName = 'BonusFilled';
export default React.forwardRef<HTMLSpanElement, IconReactProps>(BonusFilled);
