// This icon file is generated automatically.

import { IconDefinition } from '../types';

const InfoFilled: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 27.98 28',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M12.68.04A14.2 14.2 0 0 0 2.31 6.25a15 15 0 0 0-1.98 4.6A12 12 0 0 0 0 14a12 12 0 0 0 .33 3.15 14.5 14.5 0 0 0 2.48 5.3 14 14 0 0 0 9.26 5.45 18 18 0 0 0 3.84 0 14 14 0 0 0 6.55-2.73 20 20 0 0 0 2.7-2.7 14 14 0 0 0 2.72-6.55 18 18 0 0 0 0-3.83 14 14 0 0 0-3.53-7.52A14 14 0 0 0 16.09.14a19 19 0 0 0-3.41-.1m3.51 5.57a1.94 1.94 0 0 1 1 2.58 2.4 2.4 0 0 1-.99.98 2.3 2.3 0 0 1-2.91-.94 2.1 2.1 0 0 1-.06-1.54 2.3 2.3 0 0 1 1.05-1.1 2.3 2.3 0 0 1 1.91.02Zm-2.12 5.68a2.2 2.2 0 0 1 1.86 2.52 13 13 0 0 1-.63 2.83 12 12 0 0 0-.73 3.28c-.01.79.16 1 .89 1.1a3 3 0 0 0 1.36-.17l.32-.1a3 3 0 0 1-.31.98 23 23 0 0 1-2.16.77 6 6 0 0 1-1.86 0 2.4 2.4 0 0 1-1.27-.69 1.8 1.8 0 0 1-.59-1.61 10 10 0 0 1 .66-3.2 12 12 0 0 0 .7-3.13c0-.57-.09-.8-.4-.99-.17-.09-.29-.1-.77-.1a2 2 0 0 0-.98.15l-.4.14.03-.12.13-.5.1-.37.3-.12a17 17 0 0 1 2-.66 5 5 0 0 1 1.75-.01Z',
        },
      },
    ],
  },
  name: 'info',
  theme: 'filled',
};

export default InfoFilled;
