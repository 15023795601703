// This icon file is generated automatically.

import { IconDefinition } from '../types';

const SportFilled: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 24 24',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M12 21.5a9.6 9.6 0 0 1-8.75-5.8A9 9 0 0 1 2.5 12q0-1.97.75-3.7A9.6 9.6 0 0 1 8.3 3.25 9 9 0 0 1 12 2.5q1.97 0 3.7.75a9.6 9.6 0 0 1 5.05 5.05q.75 1.73.75 3.7t-.75 3.7a9.6 9.6 0 0 1-5.05 5.05q-1.73.75-3.7.75m4.83-11.71 1.54-.5.44-1.53a8.2 8.2 0 0 0-4.58-3.45l-1.48 1.05v1.57zm-9.66 0 4.08-2.86V5.36L9.77 4.3A8 8 0 0 0 5.2 7.76l.44 1.52zM6 17.28l1.36-.12.87-1.49-1.47-4.42-1.6-.56-1.15.84q0 1.7.44 3.07t1.55 2.68M12 20a8 8 0 0 0 2.6-.42l.77-1.67-.76-1.28H9.39l-.74 1.28.77 1.67A8 8 0 0 0 12 20m-2.35-4.87h4.72l1.43-4.23L12 8.24 8.23 10.9zm8.36 2.15a8 8 0 0 0 1.55-2.68q.45-1.38.44-3.07l-1.13-.8-1.62.5-1.47 4.44.87 1.49z',
        },
      },
    ],
  },
  name: 'sport',
  theme: 'filled',
};

export default SportFilled;
