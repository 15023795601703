// This icon file is generated automatically.

import { IconDefinition } from '../types';

const CaretRightOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 15.36 28.02',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M.4 27.62a1.36 1.36 0 0 1-.13-1.77l.13-.15L12.1 14 .4 2.33A1.36 1.36 0 0 1 .27.55L.4.4A1.36 1.36 0 0 1 2.17.27l.15.13 12.65 12.65a1.36 1.36 0 0 1 .13 1.77l-.13.15L2.3 27.62a1.36 1.36 0 0 1-1.91 0',
        },
      },
    ],
  },
  name: 'caret-right',
  theme: 'outlined',
};

export default CaretRightOutlined;
