// This icon file is generated automatically.

import { IconDefinition } from '../types';

const MegaphoneOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      fill: 'currentColor',
      viewBox: '0 0 26 21',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M20.95 12v-2h4.82v2zm1.52 8.97-3.85-2.89 1.21-1.6 3.86 2.9zm-2.69-15.5-1.22-1.6 3.86-2.9 1.22 1.6zM4 19.63v-5.2H2.64q-1 0-1.7-.71a2.3 2.3 0 0 1-.7-1.7V9.97q0-1 .7-1.7t1.7-.7H7.8L13.77 4v14l-5.98-3.56H6v5.2zm7.77-5.2V7.56L8.35 9.56h-5.7a.4.4 0 0 0-.3.13.4.4 0 0 0-.12.28v2.06q0 .16.13.28a.4.4 0 0 0 .28.13h5.7zm3.85.72V6.84q.78.7 1.26 1.8.48 1.07.48 2.36t-.48 2.37a5 5 0 0 1-1.26 1.79',
        },
      },
    ],
  },
  name: 'megaphone',
  theme: 'outlined',
};

export default MegaphoneOutlined;
