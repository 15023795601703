// This icon file is generated automatically.

import { IconDefinition } from '../types';

const ProjectBFilled: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 32 32',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M9.83 7.2.51 16M18.63 4.09l-6.2 6.2M21.74 6.67 11.39 17.02M19.67 14.43 9.32 24.77M27.94 12.36 20.7 19.6M25.09 18.81l-.26 2.4-9.8 9.79M18.62 19.07l-.2 2.33-6.5 6.5M10.09 10.28v2.39L3.65 19.1M24.84 9.77l-3.62 3.63M30.53 15.46l-3.1 3.11M9.05 17.52l-.26 2.13-2.55 2.56M16.07 1l-3.33 3.33-.33 2.59',
          'stroke-width': '1.5',
          stroke: '#5C667D',
        },
      },
    ],
  },
  name: 'project-b',
  theme: 'filled',
};

export default ProjectBFilled;
