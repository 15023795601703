// This icon file is generated automatically.

import { IconDefinition } from '../types';

const BanFilled: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 40 40',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M11.86 2q8.16.04 16.34 0c1.35 0 2.28.51 2.97 1.73 2.72 4.86 5.5 9.69 8.26 14.53.17.3.35.6.45.93.17.54.18 1.07-.12 1.6q-4.31 7.57-8.57 15.16a3.1 3.1 0 0 1-3.04 1.76c-2.72-.04-5.43 0-8.15 0-2.75 0-5.5-.03-8.24 0a3 3 0 0 1-2.9-1.68C6.12 31.16 3.33 26.3.57 21.45a6 6 0 0 1-.54-1.4c-.1-.46.07-.88.31-1.3q4.27-7.47 8.47-14.98A3.1 3.1 0 0 1 11.86 2m16.03 2.16c-2.62.03-5.24 0-7.86 0q-4.02.01-8.05 0c-.6 0-1.04.22-1.35.77Q6.58 12.06 2.49 19.2c-.27.46-.26.87 0 1.33q4.06 7.06 8.07 14.14c.36.64.82.89 1.53.88h15.76a1.7 1.7 0 0 0 1.65-.96q3.97-7.02 7.99-14.01c.28-.5.3-.92 0-1.43q-4.03-7.03-8.03-14.1c-.38-.66-.85-.9-1.57-.9M12.57 5.54q7.46.03 14.92 0c.5 0 .8.17 1.05.61q3.76 6.63 7.55 13.24c.19.32.2.59.01.91q-3.82 6.68-7.62 13.37c-.2.36-.46.5-.87.5q-3.82-.02-7.66-.01h-7.5c-.45.01-.72-.14-.95-.53q-3.77-6.65-7.57-13.28c-.21-.36-.2-.65 0-1q3.79-6.56 7.51-13.16c.27-.48.58-.65 1.13-.65m8.17 2.87c-.56-.04-1.02.32-1.16.92a4 4 0 0 0-.07.78v8.54c.01.3-.12.4-.41.41-.57.02-.64-.03-.64-.63v-6.56q0-.55-.16-1.07-.24-.68-.98-.67c-.47.01-.82.23-.96.7q-.14.41-.15.86-.02 3.4-.02 6.81c0 .38-.07.57-.5.57s-.48-.2-.48-.55q.02-1.8 0-3.6 0-.7-.14-1.37c-.1-.5-.46-.74-.95-.78-.47-.05-.92.2-1.08.67q-.15.44-.15.91v5.28q-.02 2.62 0 5.23a9 9 0 0 0 .76 3.64c.8 1.81 2.28 2.66 4.15 2.96 3.47.56 6.36-.48 8.27-3.62 1.58-2.62 3.38-5.1 5.06-7.66.19-.29.3-.72.25-1.06-.18-1.1-1.4-1.51-2.25-.75-.94.86-1.83 1.78-2.76 2.66-.16.14-.4.19-.61.28-.06-.2-.17-.41-.17-.62v-8.34q0-.32-.02-.64a1.34 1.34 0 0 0-1.12-1.2 1.3 1.3 0 0 0-1.37.8q-.16.43-.16.9-.03 3.15 0 6.32c0 .37-.1.53-.5.54-.43 0-.56-.15-.56-.58v-8.48q.01-.28-.01-.54a1.2 1.2 0 0 0-1.11-1.06',
        },
      },
    ],
  },
  name: 'ban',
  theme: 'filled',
};

export default BanFilled;
