// GENERATE BY ./scripts/generate.ts
// DON NOT EDIT IT MANUALLY

import * as React from 'react';
import { SlotFilled as SlotFilledSvg } from 'icons-svg';
import type { IconReactProps } from '../components/IconReact';
import IconReact from '../components/IconReact';

const SlotFilled = (
  props: IconReactProps,
  ref: React.Ref<HTMLSpanElement>,
): React.ReactElement => (
  <IconReact {...props} ref={ref} icon={SlotFilledSvg} />
);

SlotFilled.displayName = 'SlotFilled';
export default React.forwardRef<HTMLSpanElement, IconReactProps>(SlotFilled);
