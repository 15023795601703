// This icon file is generated automatically.

import { IconDefinition } from '../types';

const LineFilled: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      fill: 'currentColor',
      viewBox: '0 0 48 48',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          'clip-rule': 'evenodd',
          d: 'M7.1 47.47q.36-.02.64.2l.49.02q.51.08 1.01.2l.35.09q2.28.04 4.56-.02 1.89-.04 3.78-.02h.48q1.35.05 2.68-.03h.11q1.87.06 3.73.03h2.44q1 .03 2-.03h.12q.21.05.43.03h8.77l.08-.02q.5-.12 1-.22l.38-.03c.21-.1.44-.1.66-.1.74-.15 1.4-.48 2.05-.83q.7-.38 1.3-.86c.86-.63 1.5-1.47 2.12-2.32q.32-.5.59-1.04l.02-.05.16-.33q.2-.35.31-.74c.23-.5.33-1.04.43-1.58q.07-.42.18-.84.02-.37.01-.73V12.78l.01-.9q.03-1.22-.03-2.43l-.05-.26a4 4 0 0 1-.14-1.06 7 7 0 0 0-.66-2.3L47 5.6l-.12-.24q-.45-.89-1.06-1.67-.24-.22-.43-.5-.25-.34-.58-.61l-.36-.28c-.2-.18-.4-.36-.67-.45l-.57-.36-.57-.36Q42.48.9 42.21.8l-.06-.03a5 5 0 0 0-2.01-.58l-.39-.03a2 2 0 0 0-.4-.08Q24-.08 8.64.07q-.2.02-.4.08-.23.02-.47.12-.26.11-.56.1l-1.28.5-.36.14c-.45.1-.8.35-1.17.61l-.34.24-.35.3a4 4 0 0 0-.57.52q-.65.6-1.19 1.32-.36.5-.66 1.05-.24.45-.43.92l-.2.5q-.26.75-.43 1.52v.26c.01.4-.07.78-.14 1.16l-.06.26q-.02.37-.02.73v.2l.02 27.57.06.31q.13.49.13.99c-.05.32.04.62.13.9q.1.27.14.53.19.65.46 1.26l.22.53q.07.2.23.36l.14.19a10 10 0 0 0 4 3.7l.37.18c.38.2.75.38 1.2.35m30.2-34.55a18.6 18.6 0 0 0-9.37-4.63q-.51-.1-1.04-.18h-.1l-.22-.02a21 21 0 0 0-10.69 1.45 16 16 0 0 0-7.47 6.06c-.8 1.24-1.4 2.57-1.64 4.04l-.06.29-.11.66q-.5 4.33 1.9 7.95c1.99 3 4.8 4.95 8.1 6.22.97.37 1.97.6 2.96.84l1.2.3h.17c.27 0 .54 0 .8.1.98.26 1.22 1.08 1.1 2.03q-.04.42-.12.82-.1.49-.15.98c-.08.92.27 1.17 1.13.86 2-.72 3.77-1.91 5.5-3.1a69 69 0 0 0 6.75-5.28A18 18 0 0 0 39.76 28a11.6 11.6 0 0 0 1.46-8.05l-.01-.15-.04-.31a13.3 13.3 0 0 0-3.88-6.57',
          'fill-rule': 'evenodd',
        },
      },
      {
        tag: 'path',
        attrs: {
          d: 'M27.17 22.63c.14-.22.1-.41.1-.6v-3.28c.01-.55.35-.87.87-.88.57-.02 1 .31 1 .87q.03 3.43 0 6.87c0 .39-.2.72-.6.8-.42.1-.82.1-1.12-.31q-1.39-1.92-2.79-3.82c-.15-.2-.27-.43-.55-.6v3.72q0 1.05-.93 1.06c-.6.01-1-.33-1-.94V18.8a.9.9 0 0 1 .66-.9q.71-.2 1.17.43 1.23 1.69 2.48 3.36zM31.03 26.43c-.55-.01-.7-.43-.71-.84q-.04-3.42 0-6.82c0-.52.39-.89.88-.9q1.74-.01 3.47.01c.43 0 .82.18.93.65.1.44.09.88-.42 1.1-.78.05-1.57 0-2.35.03-.18 0-.38-.06-.53.1-.13.41-.04.83-.05 1.24-.01.28.2.22.37.22h2.01c.32 0 .61.06.78.37.1.18.09.37.09.56 0 .64-.18.83-.81.84h-2.16c-.08.02-.2-.02-.22.12-.3 1.37-.23 1.46 1.12 1.46h1.19c.57.02.93.3 1 .77.1.61-.07.86-.7 1.1q-1.95.1-3.9 0M13.22 18.12c.32-.34.72-.33 1.1-.16.4.18.52.56.52.99q.01 2.55-.01 5.1c0 .4.1.54.5.52q.96-.04 1.92 0c.76 0 1.1.46.94 1.23-.26.43-.63.55-1.12.53-1.05-.03-2.1 0-3.14 0-.24 0-.49 0-.7-.12h-.01c-.12-.1-.13-.28-.22-.4-.08-.17-.04-.34-.04-.51V19c0-.17-.04-.34.04-.5.14-.1.12-.27.22-.4M19.21 18.26c.18-.46.6-.46.94-.37.4.11.75.35.75.86v6.65c0 .32-.03.62-.31.82-.3.16-.62.12-.92.09q-.55-.09-.55-.7v-2.49q.01-2.2-.01-4.41-.01-.23.1-.45',
        },
      },
    ],
  },
  name: 'line',
  theme: 'filled',
};

export default LineFilled;
