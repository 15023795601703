// This icon file is generated automatically.

import { IconDefinition } from '../types';

const LicenseThunderFilled: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      height: '30',
      width: '40',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'm0 12.92 3.36 3.05 5.5-3.05 6.25 6.72 6.87-3.67v5.5l-6.87 3.66-6.26-6.71-5.5 3.05L0 18.42z',
        },
      },
      {
        tag: 'path',
        attrs: {
          d: 'm18.32 12.92 3.36 3.05 5.5-3.05 6.26 6.72 6.25-3.67v5.5l-6.25 3.66-6.26-6.71-5.5 3.05-3.36-3.05z',
        },
      },
      {
        tag: 'path',
        attrs: { d: 'm21.07 4.98-5.8 3.36 2.75 3.97 3.05-1.53z' },
      },
      { tag: 'path', attrs: { d: 'm40 4.98-5.8 3.36 2.75 3.97L40 10.78z' } },
    ],
  },
  name: 'license-thunder',
  theme: 'filled',
};

export default LicenseThunderFilled;
