// This icon file is generated automatically.

import { IconDefinition } from '../types';

const CylindOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      fill: 'currentColor',
      viewBox: '0 0 12 12',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M6 11.67q-2.43 0-4.05-.69T.33 9.27v-6.6Q.33 1.7 2 1.02A11 11 0 0 1 6 .33q2.35 0 4 .69 1.67.67 1.67 1.65v6.6q0 1.02-1.62 1.71T6 11.67m0-7.7q1.46 0 2.94-.41 1.47-.41 1.7-.89-.2-.5-1.69-.91a10.6 10.6 0 0 0-5.9-.01q-1.45.4-1.71.9.26.5 1.7.9 1.46.42 2.96.42m0 3.34a13 13 0 0 0 2.6-.26 9 9 0 0 0 2.07-.75V3.93q-.42.24-.95.43-.52.2-1.12.32a12 12 0 0 1-2.6.26 13 13 0 0 1-2.64-.27q-.6-.12-1.1-.31a5 5 0 0 1-.93-.43V6.3q.4.24.92.42.51.19 1.1.32A12 12 0 0 0 6 7.31m0 3.36a12 12 0 0 0 3.02-.4q.63-.2 1.08-.43.44-.25.57-.52V7.3q-.42.24-.95.43-.52.18-1.12.32A12 12 0 0 1 6 8.3a13 13 0 0 1-2.64-.27q-.6-.14-1.1-.32a5 5 0 0 1-.93-.42v2.03q.14.28.57.52.44.23 1.07.42t1.43.29 1.6.1',
        },
      },
    ],
  },
  name: 'cylind',
  theme: 'outlined',
};

export default CylindOutlined;
