// This icon file is generated automatically.

import { IconDefinition } from '../types';

const MenuFilled: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 24 24',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M21.75 6H2.25a1.5 1.5 0 0 1 0-3h19.5a1.5 1.5 0 0 1 0 3M21.75 21H2.25a1.5 1.5 0 0 1 0-3h19.5a1.5 1.5 0 0 1 0 3M21.75 13.5H2.25a1.5 1.5 0 0 1 0-3h19.5a1.5 1.5 0 0 1 0 3',
        },
      },
    ],
  },
  name: 'menu',
  theme: 'filled',
};

export default MenuFilled;
