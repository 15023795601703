// GENERATE BY ./scripts/generate.ts
// DON NOT EDIT IT MANUALLY

import * as React from 'react';
import { LicenseBmmFilled as LicenseBmmFilledSvg } from 'icons-svg';
import type { IconReactProps } from '../components/IconReact';
import IconReact from '../components/IconReact';

const LicenseBmmFilled = (
  props: IconReactProps,
  ref: React.Ref<HTMLSpanElement>,
): React.ReactElement => (
  <IconReact {...props} ref={ref} icon={LicenseBmmFilledSvg} />
);

LicenseBmmFilled.displayName = 'LicenseBmmFilled';
export default React.forwardRef<HTMLSpanElement, IconReactProps>(
  LicenseBmmFilled,
);
