// GENERATE BY ./scripts/generate.ts
// DON NOT EDIT IT MANUALLY

import * as React from 'react';
import { CorrectOutlined as CorrectOutlinedSvg } from 'icons-svg';
import type { IconReactProps } from '../components/IconReact';
import IconReact from '../components/IconReact';

const CorrectOutlined = (
  props: IconReactProps,
  ref: React.Ref<HTMLSpanElement>,
): React.ReactElement => (
  <IconReact {...props} ref={ref} icon={CorrectOutlinedSvg} />
);

CorrectOutlined.displayName = 'CorrectOutlined';
export default React.forwardRef<HTMLSpanElement, IconReactProps>(
  CorrectOutlined,
);
