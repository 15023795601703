// This icon file is generated automatically.

import { IconDefinition } from '../types';

const SocialYoutubeFilled: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 30 30',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'g',
        attrs: { 'clip-path': 'url(#clip0_2051_10304)' },
        children: [
          { tag: 'path', attrs: { d: 'm12.92 17.42 4.16-2.41-4.16-2.43z' } },
          {
            tag: 'path',
            attrs: {
              d: 'M15 0a15 15 0 1 0 0 30 15 15 0 0 0 0-30m7.9 17.65a3 3 0 0 1-3 3h-9.8a3 3 0 0 1-3-3v-5.3a3 3 0 0 1 3-3h9.8a3 3 0 0 1 3 3z',
            },
          },
        ],
      },
    ],
  },
  name: 'social-youtube',
  theme: 'filled',
};

export default SocialYoutubeFilled;
