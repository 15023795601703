// This icon file is generated automatically.

import { IconDefinition } from '../types';

const CustomerServiceFilled: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 24 24',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M11.38 20.5V19h7.31q.13 0 .22-.08t.09-.2v-7q0-2.85-2.05-4.82A7 7 0 0 0 12 4.94 7 7 0 0 0 7.05 6.9 6.4 6.4 0 0 0 5 11.73v5.89h-.75q-.73 0-1.23-.5a1.6 1.6 0 0 1-.52-1.23v-1.94q0-.48.28-.89.27-.4.72-.65l.05-1.28q.12-1.64.84-3.02a8.33 8.33 0 0 1 4.48-4.03 8.6 8.6 0 0 1 6.25 0 8.5 8.5 0 0 1 4.48 4.01q.72 1.4.85 3.03l.05 1.25q.44.2.72.59t.28.85v2.23q0 .48-.28.86t-.72.59v1.23q0 .74-.53 1.26t-1.28.52zM9.2 13.77a.87.87 0 1 1 0-1.74q.38 0 .63.25.26.26.26.62 0 .37-.26.62a1 1 0 0 1-.63.25m5.62 0a.87.87 0 1 1 0-1.74q.36 0 .62.25.26.26.26.62 0 .37-.26.62a1 1 0 0 1-.62.25M6.37 12.2A5.3 5.3 0 0 1 7.9 8.01a5.5 5.5 0 0 1 4.16-1.74q2.08 0 3.67 1.29a5.3 5.3 0 0 1 1.93 3.33 7.6 7.6 0 0 1-6.7-4.12 7.44 7.44 0 0 1-4.57 5.43',
        },
      },
    ],
  },
  name: 'customer-service',
  theme: 'filled',
};

export default CustomerServiceFilled;
