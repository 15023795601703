// GENERATE BY ./scripts/generate.ts
// DON NOT EDIT IT MANUALLY

import * as React from 'react';
import { ReturnFilled as ReturnFilledSvg } from 'icons-svg';
import type { IconReactProps } from '../components/IconReact';
import IconReact from '../components/IconReact';

const ReturnFilled = (
  props: IconReactProps,
  ref: React.Ref<HTMLSpanElement>,
): React.ReactElement => (
  <IconReact {...props} ref={ref} icon={ReturnFilledSvg} />
);

ReturnFilled.displayName = 'ReturnFilled';
export default React.forwardRef<HTMLSpanElement, IconReactProps>(ReturnFilled);
