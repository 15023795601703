// GENERATE BY ./scripts/generate.ts
// DON NOT EDIT IT MANUALLY

import * as React from 'react';
import { PromotionFilled as PromotionFilledSvg } from 'icons-svg';
import type { IconReactProps } from '../components/IconReact';
import IconReact from '../components/IconReact';

const PromotionFilled = (
  props: IconReactProps,
  ref: React.Ref<HTMLSpanElement>,
): React.ReactElement => (
  <IconReact {...props} ref={ref} icon={PromotionFilledSvg} />
);

PromotionFilled.displayName = 'PromotionFilled';
export default React.forwardRef<HTMLSpanElement, IconReactProps>(
  PromotionFilled,
);
