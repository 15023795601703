// This icon file is generated automatically.

import { IconDefinition } from '../types';

const MagnifyingGlassOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      fill: 'currentColor',
      viewBox: '0 0 12 12',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M11.03 11.72 6.84 7.53a3.8 3.8 0 0 1-2.5.88q-1.7 0-2.89-1.18a4 4 0 0 1-1.18-2.9q0-1.7 1.18-2.89A4 4 0 0 1 4.35.26q1.7 0 2.89 1.18a4 4 0 0 1 1.18 2.9 4 4 0 0 1-.88 2.49l4.2 4.19zM4.35 7.4q1.29 0 2.18-.9.9-.89.9-2.18 0-1.28-.9-2.18t-2.18-.9-2.19.9q-.9.9-.9 2.18t.9 2.19q.9.9 2.19.89',
        },
      },
    ],
  },
  name: 'magnifying-glass',
  theme: 'outlined',
};

export default MagnifyingGlassOutlined;
