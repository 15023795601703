// This icon file is generated automatically.

import { IconDefinition } from '../types';

const StarsBorderOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      fill: 'currentColor',
      viewBox: '0 0 16 16',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M1.5 12.5v-1h13v1zm.56-4-.69-.4.61-1.07H.77v-.8h1.21l-.6-1.02.68-.4.6 1.03.61-1.03.69.4-.6 1.03h1.2v.8h-1.2l.6 1.05-.69.4-.6-1.06zm5.33 0-.68-.4.6-1.07h-1.2v-.8h1.2l-.6-1.02.68-.4L8 5.84l.6-1.03.7.4-.61 1.03h1.2v.8H8.7l.6 1.05-.68.4L8 7.43zm5.34 0-.69-.4.6-1.07h-1.2v-.8h1.2l-.6-1.02.69-.4.6 1.03.6-1.03.7.4-.6 1.03h1.2v.8h-1.2l.6 1.05-.7.4-.6-1.06z',
        },
      },
    ],
  },
  name: 'stars-border',
  theme: 'outlined',
};

export default StarsBorderOutlined;
