// This icon file is generated automatically.

import { IconDefinition } from '../types';

const SocialTelegramFilled: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 30 30',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'g',
        attrs: { 'clip-path': 'url(#clip0_2051_10310)' },
        children: [
          {
            tag: 'path',
            attrs: {
              d: 'M15 30a15 15 0 1 0 0-30 15 15 0 0 0 0 30M6.86 14.68 21.33 9.1c.67-.24 1.25.16 1.04 1.18l-2.46 11.6c-.19.82-.68 1.02-1.36.63l-3.75-2.76-1.8 1.74c-.2.2-.38.37-.77.37l.27-3.82 6.95-6.27c.3-.27-.07-.42-.46-.16l-8.6 5.41-3.7-1.15c-.8-.26-.82-.8.17-1.2',
            },
          },
        ],
      },
    ],
  },
  name: 'social-telegram',
  theme: 'filled',
};

export default SocialTelegramFilled;
