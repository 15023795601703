// GENERATE BY ./scripts/generate.ts
// DON NOT EDIT IT MANUALLY

import * as React from 'react';
import { ProjectAFilled as ProjectAFilledSvg } from 'icons-svg';
import type { IconReactProps } from '../components/IconReact';
import IconReact from '../components/IconReact';

const ProjectAFilled = (
  props: IconReactProps,
  ref: React.Ref<HTMLSpanElement>,
): React.ReactElement => (
  <IconReact {...props} ref={ref} icon={ProjectAFilledSvg} />
);

ProjectAFilled.displayName = 'ProjectAFilled';
export default React.forwardRef<HTMLSpanElement, IconReactProps>(
  ProjectAFilled,
);
