// This icon file is generated automatically.

import { IconDefinition } from '../types';

const ProfileFilled: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 24 24',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'g',
        attrs: { mask: 'url(#mask0_2051_10440)' },
        children: [
          {
            tag: 'path',
            attrs: {
              d: 'M12 17.63q-1.55 0-3 .54-1.43.53-2.64 1.62v.15l.07.05.09.01h10.94l.08-.01.08-.05v-.15A8 8 0 0 0 12 17.63m-7 1.35q1.35-1.32 3.14-2.08 1.79-.77 3.86-.77a9.7 9.7 0 0 1 7 2.85V6.31a.3.3 0 0 0-.1-.21.3.3 0 0 0-.2-.1H5.3a.3.3 0 0 0-.2.1.3.3 0 0 0-.1.2zm7-5.23q-1.35 0-2.3-.95a3.1 3.1 0 0 1-.95-2.3q0-1.35.95-2.3t2.3-.95 2.3.95.95 2.3-.95 2.3-2.3.95m0-1.5q.72 0 1.24-.51.51-.52.51-1.24 0-.73-.51-1.24A1.7 1.7 0 0 0 12 8.75q-.72 0-1.24.51-.51.52-.51 1.24t.51 1.24 1.24.51M5.3 21.5q-.75 0-1.27-.52a1.7 1.7 0 0 1-.53-1.29V6.31q0-.75.53-1.28.51-.53 1.28-.53h1.38V2.38h1.54V4.5h7.58V2.38h1.5V4.5h1.38q.75 0 1.29.53.52.52.52 1.28v13.38q0 .75-.52 1.29-.53.52-1.29.52z',
            },
          },
        ],
      },
    ],
  },
  name: 'profile',
  theme: 'filled',
};

export default ProfileFilled;
