// This icon file is generated automatically.

import { IconDefinition } from '../types';

const LicenseSecurityFilled: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 30 30',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M2.5 15v12.5h25v-25h-25zm16.41-7.72 3.74 1.4-.06 3.62c-.05 3.2-.09 3.76-.33 4.69a11 11 0 0 1-5.49 7.11c-1.5.75-1.96.76-3.3.13a8 8 0 0 1-2.65-1.83 12 12 0 0 1-3.08-5.34c-.25-.88-.29-1.4-.34-4.7l-.05-3.7 3.68-1.38a54 54 0 0 1 3.9-1.4c.14 0 1.93.62 3.98 1.4',
        },
      },
      {
        tag: 'path',
        attrs: {
          d: 'm13.94 7.74-2.96 1.13-2.1.8v2.85c0 4.12.37 5.6 1.92 7.65a9 9 0 0 0 3.17 2.67l.92.46.58-.21a9.3 9.3 0 0 0 4.88-4.89c.64-1.58.67-1.84.74-5.28l.06-3.26-3.05-1.14a50 50 0 0 0-3.18-1.13c-.06 0-.5.16-.98.35m2.3 2.76c.54.37.89 1.14.89 1.96v.66h.42c.24 0 .55.12.71.24.3.24.3.26.3 2.86 0 2.4-.02 2.65-.23 2.9-.23.24-.35.25-3.34.25h-3.1l-.27-.33c-.24-.33-.26-.45-.22-2.89.04-2.5.04-2.55.34-2.79.16-.12.47-.24.71-.24h.43v-.68c0-1.05.5-1.88 1.37-2.23a3 3 0 0 1 1.99.29',
        },
      },
      {
        tag: 'path',
        attrs: {
          d: 'M14.28 11.08c-.42.28-.65.83-.65 1.48v.56h2.75v-.5c0-.64-.27-1.28-.64-1.54-.38-.26-1.1-.27-1.46 0M14.39 15.82c-.34.43-.33.63.05 1q.52.54 1.04.08.57-.5.13-1.06c-.35-.45-.87-.45-1.22-.02',
        },
      },
    ],
  },
  name: 'license-security',
  theme: 'filled',
};

export default LicenseSecurityFilled;
