// This icon file is generated automatically.

import { IconDefinition } from '../types';

const ArrowLeftOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      fill: 'currentColor',
      viewBox: '0 0 12 12',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M5.73 11.45 0 5.73 5.73 0l1.32 1.3-3.46 3.47h8.1v1.92H3.6l3.46 3.45z',
        },
      },
    ],
  },
  name: 'arrow-left',
  theme: 'outlined',
};

export default ArrowLeftOutlined;
