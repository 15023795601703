// GENERATE BY ./scripts/generate.ts
// DON NOT EDIT IT MANUALLY

import * as React from 'react';
import { SocialTelegramFilled as SocialTelegramFilledSvg } from 'icons-svg';
import type { IconReactProps } from '../components/IconReact';
import IconReact from '../components/IconReact';

const SocialTelegramFilled = (
  props: IconReactProps,
  ref: React.Ref<HTMLSpanElement>,
): React.ReactElement => (
  <IconReact {...props} ref={ref} icon={SocialTelegramFilledSvg} />
);

SocialTelegramFilled.displayName = 'SocialTelegramFilled';
export default React.forwardRef<HTMLSpanElement, IconReactProps>(
  SocialTelegramFilled,
);
