// This icon file is generated automatically.

import { IconDefinition } from '../types';

const SlotFilled: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 24 24',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M21.64 3.52A1.7 1.7 0 0 0 20.36 3H3.21q-.76 0-1.28.52-.52.53-.52 1.28v13.39q0 .76.52 1.28.53.52 1.28.52h17.15q.76 0 1.28-.52.53-.53.53-1.28V4.81q0-.76-.53-1.28zM3 4.59a.3.3 0 0 1 .21-.1h17.15q.12 0 .21.1.1.1.1.21v5.78H2.9V4.81q0-.12.1-.21zM14.35 18.5H9.4v-6.51h4.95zM3 18.4a.3.3 0 0 1-.1-.21v-6.2h5.26v6.51H3.21a.3.3 0 0 1-.21-.1m17.58 0a.3.3 0 0 1-.21.1h-4.78v-6.51h5.09v6.2q0 .12-.1.21',
        },
      },
      {
        tag: 'path',
        attrs: {
          d: 'M6.1 9.17c-.4 0-.63-.23-.66-.58h-.93c.01.82.71 1.29 1.6 1.29.98 0 1.53-.61 1.53-1.29 0-1.52-2.22-1.04-2.22-1.91 0-.35.25-.52.6-.51.36 0 .6.23.61.51h.95c-.06-.78-.66-1.23-1.55-1.23s-1.52.47-1.52 1.25c0 1.57 2.22 1 2.22 1.94 0 .31-.24.53-.65.53zM10.69 9.15H9.26V5.51H8.4v4.32h2.29zM13.24 9.88c1.23 0 2.21-.92 2.21-2.22s-.98-2.21-2.2-2.21c-1.24 0-2.23.92-2.23 2.21s1 2.22 2.22 2.22m0-3.65c.78 0 1.32.55 1.32 1.43s-.54 1.44-1.32 1.44-1.32-.56-1.32-1.44.54-1.43 1.32-1.43M17.02 9.83h.87V6.21h1.15v-.7h-3.17v.7h1.15zM4.36 13.41v.67h1.93L4.78 17.5h.8l1.49-3.51v-.58zM10.96 17.5h.8l1.49-3.51v-.58h-2.71v.67h1.93zM16.72 14.08h1.93l-1.5 3.42h.8l1.5-3.51v-.58h-2.73z',
        },
      },
    ],
  },
  name: 'slot',
  theme: 'filled',
};

export default SlotFilled;
