// This icon file is generated automatically.

import { IconDefinition } from '../types';

const CheckOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 50 50',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M35.5 50h-21c-4.5 0-7 0-9-1S2 46.5 1.25 44.75C0 42.5 0 40 0 35.5v-21c0-4.5 0-7 1-9S3.5 2 5.25 1.25C7.5 0 10 0 14.5 0h21c4.5 0 7 0 9 1s3.5 2.5 4.25 4.25C50 7.5 50 10 50 14.5v21c0 4.5 0 7-1 9s-2.5 3.5-4.25 4.25C42.5 50 40 50 35.5 50m-21-45c-3.75 0-5.75 0-6.75.5A5 5 0 0 0 5.5 7.75c-.5 1-.5 3-.5 6.75v21c0 3.75 0 5.75.5 6.75a5 5 0 0 0 2.25 2.25c1 .5 3 .5 6.75.5h21c3.75 0 5.75 0 6.75-.5a5 5 0 0 0 2.25-2.25c.5-1 .5-3 .5-6.75v-21c0-3.75 0-5.75-.5-6.75a5 5 0 0 0-2.25-2.25c-1-.5-3-.5-6.75-.5zm6.75 30c-.75 0-1.25-.25-1.75-.75l-7.5-7.5c-1-1-1-2.5 0-3.5s2.5-1 3.5 0L21.25 29 34.5 15.75c1-1 2.5-1 3.5 0s1 2.5 0 3.5l-15 15c-.5.5-1 .75-1.75.75',
        },
      },
    ],
  },
  name: 'check',
  theme: 'outlined',
};

export default CheckOutlined;
