// This icon file is generated automatically.

import { IconDefinition } from '../types';

const CreditCardOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      fill: 'currentColor',
      viewBox: '0 0 14 10',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M13.33 1.2v7.6q0 .5-.35.85t-.85.35H1.87q-.5 0-.85-.35a1.2 1.2 0 0 1-.35-.86V1.21q0-.51.35-.86T1.87 0h10.26q.5 0 .85.35t.35.86M1.67 2.6h10.66V1.2a.2.2 0 0 0-.06-.14.2.2 0 0 0-.14-.06H1.87a.2.2 0 0 0-.14.06.2.2 0 0 0-.06.15zm0 2.13V8.8q0 .08.06.15a.2.2 0 0 0 .14.06h10.26a.2.2 0 0 0 .14-.06.2.2 0 0 0 .06-.15V4.73z',
        },
      },
    ],
  },
  name: 'credit-card',
  theme: 'outlined',
};

export default CreditCardOutlined;
