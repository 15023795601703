// This icon file is generated automatically.

import { IconDefinition } from '../types';

const RerollOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 16 16',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M8.04 15.62q-3.15 0-5.32-2.18A7.2 7.2 0 0 1 .54 8.12q0-3.15 2.18-5.32A7.2 7.2 0 0 1 8.04.62a7.4 7.4 0 0 1 5.92 2.98V.62h1.5v6.11H9.35v-1.5h3.95a5.9 5.9 0 0 0-5.26-3.11q-2.5 0-4.25 1.75a5.8 5.8 0 0 0-1.75 4.25q0 2.5 1.75 4.25a5.8 5.8 0 0 0 4.25 1.75q1.92 0 3.47-1.1a5.8 5.8 0 0 0 2.18-2.9h1.58a7.3 7.3 0 0 1-2.68 3.97 7.3 7.3 0 0 1-4.55 1.53',
          fill: 'currentColor',
        },
      },
    ],
  },
  name: 'reroll',
  theme: 'outlined',
};

export default RerollOutlined;
