// This icon file is generated automatically.

import { IconDefinition } from '../types';

const CloseCircleOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      fill: 'currentColor',
      viewBox: '0 0 14 14',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M4.6 10.1 7 7.7l2.4 2.4.7-.7L7.7 7l2.4-2.4-.7-.7L7 6.3 4.6 3.9l-.7.7L6.3 7 3.9 9.4zM7 13.33a6.2 6.2 0 0 1-4.48-1.85 6.4 6.4 0 0 1-1.35-6.95 6.4 6.4 0 0 1 3.36-3.36Q5.69.67 7 .67a6.2 6.2 0 0 1 4.48 1.85 6.4 6.4 0 0 1 1.35 6.95 6.4 6.4 0 0 1-3.36 3.36q-1.16.5-2.47.5m0-1q2.24 0 3.78-1.55A5.2 5.2 0 0 0 12.33 7q0-2.23-1.55-3.78A5.2 5.2 0 0 0 7 1.67q-2.24 0-3.78 1.55A5.2 5.2 0 0 0 1.67 7q0 2.23 1.55 3.78A5.2 5.2 0 0 0 7 12.33',
        },
      },
    ],
  },
  name: 'close-circle',
  theme: 'outlined',
};

export default CloseCircleOutlined;
