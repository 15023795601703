// This icon file is generated automatically.

import { IconDefinition } from '../types';

const CheckCircleOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      fill: 'currentColor',
      viewBox: '0 0 14 14',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'm6.05 9.84 4.49-4.49-.7-.7-3.79 3.78-1.9-1.9-.7.7zm.95 3.5a6.2 6.2 0 0 1-4.48-1.86 6.4 6.4 0 0 1-1.35-6.95 6.4 6.4 0 0 1 3.36-3.36Q5.69.67 7 .67a6.2 6.2 0 0 1 4.48 1.85 6.4 6.4 0 0 1 1.35 6.95 6.4 6.4 0 0 1-3.36 3.36q-1.16.5-2.47.5m0-1q2.24 0 3.78-1.56A5.2 5.2 0 0 0 12.33 7q0-2.23-1.55-3.78A5.2 5.2 0 0 0 7 1.67q-2.24 0-3.78 1.55A5.2 5.2 0 0 0 1.67 7q0 2.23 1.55 3.78A5.2 5.2 0 0 0 7 12.33',
        },
      },
    ],
  },
  name: 'check-circle',
  theme: 'outlined',
};

export default CheckCircleOutlined;
