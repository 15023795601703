// This icon file is generated automatically.

import { IconDefinition } from '../types';

const PromotionFilled: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 24 24',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'm13 17.21 3.27-3.33a1.8 1.8 0 0 0 .52-1.3q0-.73-.53-1.27a1.7 1.7 0 0 0-1.27-.54q-.48 0-.95.26-.47.27-1.04.92a5 5 0 0 0-1.08-.93q-.45-.25-.91-.25-.75 0-1.27.54-.53.53-.53 1.28a1.9 1.9 0 0 0 .52 1.3zm.9 3.84q-.42.44-1.07.43-.63 0-1.06-.43l-8.8-8.8a2 2 0 0 1-.35-.5 2 2 0 0 1-.12-.6V4q0-.6.44-1.06Q3.4 2.5 4 2.5h7.15a1.5 1.5 0 0 1 1.08.45l8.8 8.81q.45.44.45 1.08 0 .63-.43 1.06zM12.83 20l7.15-7.15L11.15 4H4v7.15zM6.5 7.75q.52 0 .88-.37.37-.36.37-.88t-.37-.88a1.2 1.2 0 0 0-.88-.37q-.52 0-.88.37a1.2 1.2 0 0 0-.37.88q0 .52.37.88.36.37.88.37',
        },
      },
    ],
  },
  name: 'promotion',
  theme: 'filled',
};

export default PromotionFilled;
