// This icon file is generated automatically.

import { IconDefinition } from '../types';

const UserOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      fill: 'currentColor',
      viewBox: '0 0 16 16',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M8 7.8q-.97 0-1.65-.7-.68-.68-.68-1.64t.68-1.65q.68-.68 1.65-.68t1.65.68.68 1.65q0 .97-.68 1.65T8 7.79m-5 5.07V11.4q0-.5.27-.9.26-.43.7-.65a8.63 8.63 0 0 1 8.05 0A1.8 1.8 0 0 1 13 11.4v1.48zm1-1h8v-.48a.7.7 0 0 0-.12-.38 1 1 0 0 0-.32-.28 7.6 7.6 0 0 0-7.13 0 1 1 0 0 0-.31.28.7.7 0 0 0-.12.38zM8 6.8q.55 0 .94-.39.4-.39.4-.94t-.4-.94-.94-.4-.94.4q-.4.39-.4.94t.4.94q.39.4.94.4',
        },
      },
    ],
  },
  name: 'user',
  theme: 'outlined',
};

export default UserOutlined;
