// This icon file is generated automatically.

import { IconDefinition } from '../types';

const HomeFilled: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 24 24',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M6 19h3.35v-5.94h5.3V19H18v-9l-6-4.52L6 10zm-1.5 1.5V9.25L12 3.61l7.5 5.64V20.5h-6.35v-5.94h-2.3v5.94z',
        },
      },
    ],
  },
  name: 'home',
  theme: 'filled',
};

export default HomeFilled;
