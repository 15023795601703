// This icon file is generated automatically.

import { IconDefinition } from '../types';

const DownArrowFilled: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 16 16',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'm8.35 11.35 5-5a.5.5 0 0 0-.7-.7L8 10.29 3.35 5.65a.5.5 0 1 0-.7.7l5 5a.5.5 0 0 0 .7 0',
        },
      },
    ],
  },
  name: 'down-arrow',
  theme: 'filled',
};

export default DownArrowFilled;
