// This icon file is generated automatically.

import { IconDefinition } from '../types';

const SanctuaryOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      fill: 'currentColor',
      viewBox: '0 0 20 20',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M3.75 16V8.5h1.5V16zm5.5 0V8.5h1.5V16zM.77 19.5V18h18.46v1.5zM14.75 16V8.5h1.5V16zM.77 6.5V5.08L10 .56l9.23 4.52V6.5zM4.32 5h11.36L10 2.25z',
        },
      },
    ],
  },
  name: 'sanctuary',
  theme: 'outlined',
};

export default SanctuaryOutlined;
