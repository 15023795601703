// This icon file is generated automatically.

import { IconDefinition } from '../types';

const ChangePasswordOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 24 24',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M4.33 19.67v-1.5h2.9a9 9 0 0 1-2.26-2.86 7.6 7.6 0 0 1-.72-3.27q0-2.63 1.55-4.67A7.6 7.6 0 0 1 9.75 4.6v1.58a6 6 0 0 0-2.88 2.28 6 6 0 0 0-1.12 3.58q0 1.44.59 2.64a8 8 0 0 0 1.87 2.35v-2.74h1.5v5.38zm13.92-7.65q0-1.42-.58-2.62a8 8 0 0 0-1.88-2.42v2.73h-1.5V4.33h5.38v1.5h-2.9q1.68 1.47 2.33 3.03.66 1.57.65 3.16zm-2.17 9.85q-.4 0-.68-.29a1 1 0 0 1-.28-.68v-2.84q0-.4.28-.68a1 1 0 0 1 .68-.28h.05v-1q0-.79.54-1.34.56-.55 1.34-.55t1.34.55q.54.55.54 1.34v1h.05q.4 0 .67.28t.27.68v2.84q0 .4-.28.68a1 1 0 0 1-.68.29zM17 17.1h2v-1q0-.43-.29-.72a1 1 0 0 0-.71-.28q-.42 0-.71.28-.3.3-.3.72z',
        },
      },
    ],
  },
  name: 'change-password',
  theme: 'outlined',
};

export default ChangePasswordOutlined;
