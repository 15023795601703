// This icon file is generated automatically.

import { IconDefinition } from '../types';

const ExceptionOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 24 24',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M16.21 19.5h.89V16h-.89zm.44-4.41q.18 0 .32-.13.13-.14.13-.32a.4.4 0 0 0-.14-.3.43.43 0 0 0-.62 0 .43.43 0 0 0 0 .62q.14.13.31.13M5 4v6.18-.03V20zm2.25 9.75h3.47a6 6 0 0 1 1.09-1.5H7.25zm0 4h2.77a5 5 0 0 1-.01-1.5H7.25zM5.31 21.5q-.75 0-1.28-.52a1.7 1.7 0 0 1-.53-1.29V4.31q0-.75.53-1.28.51-.53 1.28-.53h7.94l5.25 5.25v2.68a6 6 0 0 0-1.5-.25V8.5h-4.5V4H5.3a.3.3 0 0 0-.2.1.3.3 0 0 0-.1.2v15.4q0 .1.1.2t.2.1h5.46a6 6 0 0 0 1.1 1.5zm11.34-9.15q1.87 0 3.19 1.31a4.3 4.3 0 0 1 1.31 3.19q0 1.86-1.31 3.18a4.3 4.3 0 0 1-3.19 1.32 4.3 4.3 0 0 1-3.18-1.32 4.3 4.3 0 0 1-1.32-3.18q0-1.87 1.32-3.19a4.3 4.3 0 0 1 3.18-1.31',
        },
      },
    ],
  },
  name: 'exception',
  theme: 'outlined',
};

export default ExceptionOutlined;
