// GENERATE BY ./scripts/generate.ts
// DON NOT EDIT IT MANUALLY

import * as React from 'react';
import { HomeFilled as HomeFilledSvg } from 'icons-svg';
import type { IconReactProps } from '../components/IconReact';
import IconReact from '../components/IconReact';

const HomeFilled = (
  props: IconReactProps,
  ref: React.Ref<HTMLSpanElement>,
): React.ReactElement => (
  <IconReact {...props} ref={ref} icon={HomeFilledSvg} />
);

HomeFilled.displayName = 'HomeFilled';
export default React.forwardRef<HTMLSpanElement, IconReactProps>(HomeFilled);
