// This icon file is generated automatically.

import { IconDefinition } from '../types';

const PhoneOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      fill: 'currentColor',
      viewBox: '0 0 12 12',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M10.96 11.67q-1.89 0-3.79-.88a12 12 0 0 1-3.5-2.47 12 12 0 0 1-2.46-3.5 9 9 0 0 1-.88-3.78.7.7 0 0 1 .7-.7h2.18q.25 0 .44.16.2.16.25.4l.38 1.97q.04.27-.02.47a1 1 0 0 1-.2.33l-1.54 1.5q.37.68.85 1.28a13 13 0 0 0 2.2 2.18q.62.46 1.34.88L8.4 8q.15-.17.37-.23.22-.07.46-.03l1.86.37q.24.07.4.26t.17.44v2.16q0 .3-.2.5t-.51.2M2.05 4.22l1.19-1.14.04-.07v-.08l-.3-1.5-.04-.07-.08-.03H1.43a.1.1 0 0 0-.09.1 9 9 0 0 0 .7 2.79m5.8 5.76q.66.3 1.38.47t1.35.2a.1.1 0 0 0 .09-.1v-1.4q0-.05-.03-.08l-.08-.04-1.4-.29H9.1l-.06.04z',
        },
      },
    ],
  },
  name: 'phone',
  theme: 'outlined',
};

export default PhoneOutlined;
