// This icon file is generated automatically.

import { IconDefinition } from '../types';

const YoutubeFilled: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 22 16',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          'clip-rule': 'evenodd',
          d: 'M17.74 0H4.08A4.07 4.07 0 0 0 0 4.18v7.64A4.07 4.07 0 0 0 4.08 16h13.66c2.3 0 4.26-1.82 4.26-4.18V4.18A4.2 4.2 0 0 0 17.74 0m-3.2 8L8.7 4.55v6.9z',
          'fill-rule': 'evenodd',
          fill: 'currentColor',
        },
      },
    ],
  },
  name: 'youtube',
  theme: 'filled',
};

export default YoutubeFilled;
