// This icon file is generated automatically.

import { IconDefinition } from '../types';

const LogoutOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 24 24',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M20.37 12.75H8.57v-1.5h11.8l-1.82-1.82 1.06-1.08L23.26 12l-3.65 3.65-1.06-1.08zM15.2 8.87V5.3a.3.3 0 0 0-.08-.22.3.3 0 0 0-.23-.09H5.31a.3.3 0 0 0-.22.09.3.3 0 0 0-.09.22v13.38q0 .15.09.22a.3.3 0 0 0 .22.09h9.58a.3.3 0 0 0 .23-.09.3.3 0 0 0 .08-.22v-3.56h1.5v3.56q0 .75-.53 1.28t-1.28.53H5.31q-.75 0-1.28-.53a1.7 1.7 0 0 1-.53-1.28V5.31q0-.75.53-1.28t1.28-.53h9.58q.75 0 1.28.53.54.53.53 1.28v3.56z',
        },
      },
    ],
  },
  name: 'logout',
  theme: 'outlined',
};

export default LogoutOutlined;
