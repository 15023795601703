// This icon file is generated automatically.

import { IconDefinition } from '../types';

const ChangeWalletOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 16 16',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'g',
        attrs: { mask: 'url(#mask0_2051_10464)' },
        children: [
          {
            tag: 'path',
            attrs: {
              d: 'M5.54 11q-.5 0-.85-.35a1.2 1.2 0 0 1-.36-.86V6.21q0-.5.36-.86.35-.35.85-.35h.59l.66-.67h2.42l.66.67h.6q.48 0 .84.35.36.36.36.86v3.58q0 .5-.36.86-.35.35-.85.35zm0-1h4.92a.2.2 0 0 0 .15-.06.2.2 0 0 0 .06-.15V6.21a.2.2 0 0 0-.06-.15.2.2 0 0 0-.15-.06H5.54a.2.2 0 0 0-.15.06.2.2 0 0 0-.06.15v3.58q0 .09.06.15a.2.2 0 0 0 .15.06M6.16.56q.45-.12.9-.18.46-.05.93-.05a7.6 7.6 0 0 1 5.2 2.01 7.8 7.8 0 0 1 2.48 5h-1a6.5 6.5 0 0 0-2.05-4.16 7 7 0 0 0-4.26-1.83l1.16 1.17-.7.7zm3.68 14.88q-.45.12-.9.18-.46.05-.93.05a7.6 7.6 0 0 1-5.2-2.01 7.8 7.8 0 0 1-2.48-5h1a6.7 6.7 0 0 0 6.31 5.99l-1.16-1.17.7-.7z',
            },
          },
        ],
      },
    ],
  },
  name: 'change-wallet',
  theme: 'outlined',
};

export default ChangeWalletOutlined;
