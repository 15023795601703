// This icon file is generated automatically.

import { IconDefinition } from '../types';

const LockStarOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      fill: 'currentColor',
      viewBox: '0 0 46 48',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M25.06 34.31a1.87 1.87 0 1 0 0-3.75 1.87 1.87 0 0 0 0 3.75',
        },
      },
      {
        tag: 'path',
        attrs: {
          d: 'M31.34 44.25H7.99a3.75 3.75 0 0 1-3.75-3.75V25.12A3.75 3.75 0 0 1 8 21.37h27q.64 0 1.04.12a1.88 1.88 0 0 0 1.03-3.6 8 8 0 0 0-2.07-.27h-2.25v-6.6C32.74 4.93 27.7 0 21.5 0S10.24 4.94 10.24 11.01v6.62H8a7.5 7.5 0 0 0-7.5 7.5V40.5A7.5 7.5 0 0 0 8 48h23.35a1.87 1.87 0 1 0 0-3.75M13.99 11.01c0-4 3.37-7.26 7.5-7.26a7.4 7.4 0 0 1 7.5 7.26v6.62H14z',
        },
      },
      {
        tag: 'path',
        attrs: {
          d: 'M18.03 34.31a1.88 1.88 0 1 0 0-3.75 1.88 1.88 0 0 0 0 3.75M45.5 31.05a6.67 6.67 0 0 0-13.31.08 1.87 1.87 0 1 0 3.75 0 2.91 2.91 0 1 1 4.05 2.68 5 5 0 0 0-3.02 4.6v1.62a1.87 1.87 0 1 0 3.75 0v-1.62c0-.51.3-.96.74-1.15a6.65 6.65 0 0 0 4.04-6.2M38.84 48a1.88 1.88 0 1 0 0-3.75 1.88 1.88 0 0 0 0 3.75M11 34.31a1.87 1.87 0 1 0 0-3.75 1.87 1.87 0 0 0 0 3.75',
        },
      },
    ],
  },
  name: 'lock-star',
  theme: 'outlined',
};

export default LockStarOutlined;
