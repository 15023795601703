// This icon file is generated automatically.

import { IconDefinition } from '../types';

const GiftBoxOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      fill: 'currentColor',
      viewBox: '0 0 14 14',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M1.74 13.33V6.08H.67V2.6H4.1a1 1 0 0 1-.19-.36 2 2 0 0 1-.05-.43q0-.75.53-1.27A1.7 1.7 0 0 1 6.38.17q.32.16.58.43.26-.28.59-.43t.7-.16q.75 0 1.28.53A1.7 1.7 0 0 1 10 2.23q-.05.2-.2.37h3.53v3.48h-1.07v7.25zM8.26 1.01a.8.8 0 0 0-.57.23.8.8 0 0 0-.23.57q0 .33.23.56t.57.23.56-.23a.8.8 0 0 0 .23-.56.8.8 0 0 0-.23-.57.8.8 0 0 0-.56-.23m-3.39.8q0 .33.23.56.22.23.57.23.33 0 .56-.23a.8.8 0 0 0 .23-.56.8.8 0 0 0-.23-.57.8.8 0 0 0-.56-.23.8.8 0 0 0-.57.23.8.8 0 0 0-.23.57m-3.2 1.8v1.47H6.5V3.6zm4.83 8.72V6.08H2.74v6.25zm1 0h3.76V6.08H7.5zm4.83-7.25V3.6H7.5v1.48z',
        },
      },
    ],
  },
  name: 'gift-box',
  theme: 'outlined',
};

export default GiftBoxOutlined;
