// This icon file is generated automatically.

import { IconDefinition } from '../types';

const WalletOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      fill: 'currentColor',
      viewBox: '0 0 13 12',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M1.54 11.67q-.5 0-.85-.36a1.2 1.2 0 0 1-.36-.85V1.54q0-.5.36-.85.35-.36.85-.36h8.92q.5 0 .85.36.36.35.36.85v1.81h-1V1.54a.2.2 0 0 0-.06-.15.2.2 0 0 0-.15-.06H1.54a.2.2 0 0 0-.15.06.2.2 0 0 0-.06.15v8.92q0 .09.06.15a.2.2 0 0 0 .15.06h8.92a.2.2 0 0 0 .15-.06.2.2 0 0 0 .06-.15V8.65h1v1.81q0 .5-.36.85-.35.36-.85.36zM6.87 9q-.5 0-.85-.35a1.2 1.2 0 0 1-.35-.86V4.21q0-.5.35-.86.36-.35.85-.35h4.26q.5 0 .85.35.35.36.35.86v3.58q0 .5-.35.86-.36.35-.85.35zm4.26-1a.2.2 0 0 0 .15-.06.2.2 0 0 0 .05-.15V4.21a.2.2 0 0 0-.05-.15.2.2 0 0 0-.15-.06H6.87a.2.2 0 0 0-.15.06.2.2 0 0 0-.05.15v3.58q0 .09.05.15a.2.2 0 0 0 .15.06zM8.67 7q.41 0 .7-.3.3-.28.3-.7t-.3-.7a1 1 0 0 0-.7-.3q-.42 0-.71.3-.3.28-.3.7t.3.7q.3.3.7.3',
        },
      },
    ],
  },
  name: 'wallet',
  theme: 'outlined',
};

export default WalletOutlined;
