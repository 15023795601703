// This icon file is generated automatically.

import { IconDefinition } from '../types';

const Age18Outlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 40 40',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M24.57 2.65v3.26C19.2 4.35 14.32 5.22 10.1 8.93A14 14 0 0 0 5.2 19.5a14.6 14.6 0 0 0 15.76 14.98 14.1 14.1 0 0 0 11.1-6.52C34.7 24 35.15 19.7 33.8 15.2l.03-.05.03-.02 2.88-.02.32.01q1.06 3.81.4 7.7a17.5 17.5 0 0 1-5.97 10.55 17.75 17.75 0 0 1-23.03.2 17.76 17.76 0 0 1-4.23-22.3 17.8 17.8 0 0 1 20.34-8.62',
        },
      },
      {
        tag: 'path',
        attrs: {
          d: 'M25.9 13.66c.85.23 1.6.65 2.12 1.44.82 1.28.66 3.1-.77 4.15l-.22.15.45.26c1.1.7 1.7 1.7 1.66 3.06a3.2 3.2 0 0 1-1.5 2.65c-.6.42-1.28.65-1.99.78q-1.8.32-3.59-.13a5 5 0 0 1-1.7-.84 3.3 3.3 0 0 1-.42-4.7q.59-.68 1.44-1.06l-.55-.45a2.96 2.96 0 0 1 .44-4.86 5 5 0 0 1 1.97-.62q1.35-.19 2.65.17m-2.67 7.16c-.7.19-1.1.72-1.13 1.44-.02.74.35 1.3 1.06 1.52q.38.1.78.14.27.03.53 0 .43-.04.84-.2c1.05-.4 1.06-1.77.6-2.37a1.6 1.6 0 0 0-.87-.53 3.5 3.5 0 0 0-1.8 0m.35-5.2q-1.16.24-1.14 1.44.01.88.8 1.16l.1.03a3 3 0 0 0 1.7-.03q.5-.14.72-.64c.3-.72.07-1.71-.95-1.94q-.6-.13-1.23-.01m9-12.19c.03 1.22 0 2.41.03 3.63h3.62c.08.41.07 2.35 0 2.68H32.6c-.03 1.23 0 2.43-.02 3.67-.89.03-1.75.01-2.63.02-.07-.44-.03-.84-.03-1.23V9.76h-3.56c-.1-.3-.11-2.29-.02-2.68h3.57V3.43zM12.97 26.21V16.06h-2.32c-.09-.33-.1-2.3 0-2.63h5.58c.08.28.09 12.43.02 12.8-.33.08-2.94.07-3.28-.02',
        },
      },
    ],
  },
  name: 'age18',
  theme: 'outlined',
};

export default Age18Outlined;
