// GENERATE BY ./scripts/generate.ts
// DON NOT EDIT IT MANUALLY

import * as React from 'react';
import { Age18Outlined as Age18OutlinedSvg } from 'icons-svg';
import type { IconReactProps } from '../components/IconReact';
import IconReact from '../components/IconReact';

const Age18Outlined = (
  props: IconReactProps,
  ref: React.Ref<HTMLSpanElement>,
): React.ReactElement => (
  <IconReact {...props} ref={ref} icon={Age18OutlinedSvg} />
);

Age18Outlined.displayName = 'Age18Outlined';
export default React.forwardRef<HTMLSpanElement, IconReactProps>(Age18Outlined);
