// This icon file is generated automatically.

import { IconDefinition } from '../types';

const GameFilled: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 24 24',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M4.72 19.75q-1.41 0-2.4-1a3.4 3.4 0 0 1-.93-3.27l2.1-8.4a3.7 3.7 0 0 1 3.63-2.83h9.75q1.3 0 2.3.78a3.7 3.7 0 0 1 1.34 2.05l2.1 8.4a6 6 0 0 1 .11.87q0 1.43-1 2.41-1.03.99-2.44.99a3.4 3.4 0 0 1-3.08-1.92l-.71-1.45q-.15-.32-.47-.47-.3-.16-.65-.16H9.62q-.34 0-.64.15a1 1 0 0 0-.47.47l-.7 1.46q-.42.9-1.27 1.4-.83.52-1.82.52m.08-1.5q.54 0 1-.29.45-.3.67-.77l.7-1.43q.35-.71 1.01-1.11.68-.4 1.44-.4h4.75q.78 0 1.45.42.67.4 1.03 1.1l.7 1.42a1.8 1.8 0 0 0 1.68 1.06 1.95 1.95 0 0 0 1.97-1.87l-.06-.54-2.1-8.37q-.2-.76-.8-1.24-.59-.48-1.37-.48H7.12q-.78 0-1.39.48t-.77 1.24l-2.1 8.37q-.04.14-.06.51 0 .81.59 1.35a2 2 0 0 0 1.41.55m8.7-7.37q.36 0 .63-.25a.9.9 0 0 0 .25-.63.9.9 0 0 0-.25-.63.9.9 0 0 0-.63-.25.9.9 0 0 0-.63.25.9.9 0 0 0-.25.63q0 .36.25.63.26.25.63.25m2-2q.36 0 .63-.25a.9.9 0 0 0 .25-.63.9.9 0 0 0-.25-.63.9.9 0 0 0-.63-.25.9.9 0 0 0-.63.25.9.9 0 0 0-.25.63q0 .36.25.63.26.25.63.25m0 4q.36 0 .63-.25a.9.9 0 0 0 .25-.63.9.9 0 0 0-.25-.63.9.9 0 0 0-.63-.25.9.9 0 0 0-.63.25.9.9 0 0 0-.25.63q0 .36.25.63.26.25.63.25m2-2q.36 0 .63-.25a.9.9 0 0 0 .25-.63.9.9 0 0 0-.25-.63.9.9 0 0 0-.63-.25.9.9 0 0 0-.63.25.9.9 0 0 0-.25.63q0 .36.25.63.26.25.63.25m-9 1.47a.6.6 0 0 0 .6-.6V10.6h1.15a.6.6 0 0 0 .6-.6.6.6 0 0 0-.6-.6H9.1V8.25a.6.6 0 0 0-.6-.6.6.6 0 0 0-.6.6V9.4H6.75a.6.6 0 0 0-.6.6.6.6 0 0 0 .6.6H7.9v1.15a.6.6 0 0 0 .6.6',
        },
      },
    ],
  },
  name: 'game',
  theme: 'filled',
};

export default GameFilled;
