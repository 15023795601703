// This icon file is generated automatically.

import { IconDefinition } from '../types';

const ReturnFilled: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 24 24',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'g',
        attrs: {
          'clip-path': 'url(#clip0_2051_10433)',
          mask: 'url(#mask0_2051_10433)',
        },
        children: [
          {
            tag: 'path',
            attrs: {
              d: 'M6.9 10.75c.2.2.5.2.7 0l.65-.65a.5.5 0 0 0 0-.7l-.65-.65h8.9c.83 0 1.5.67 1.5 1.5 0 .41.34.75.75.75h.5c.41 0 .75-.34.75-.75a3.5 3.5 0 0 0-3.5-3.5H7.6l.65-.65a.5.5 0 0 0 0-.7l-.65-.65a.5.5 0 0 0-.7 0L4.07 7.57c-.1.1-.1.26 0 .36zM17.1 13.25a.5.5 0 0 0-.7 0l-.65.65a.5.5 0 0 0 0 .7l.65.65H7.5a1.5 1.5 0 0 1-1.5-1.5.75.75 0 0 0-.75-.75h-.5a.75.75 0 0 0-.75.75 3.5 3.5 0 0 0 3.5 3.5h8.9l-.65.65a.5.5 0 0 0 0 .7l.65.65c.2.2.5.2.7 0l2.83-2.82c.1-.1.1-.26 0-.36z',
            },
          },
        ],
      },
    ],
  },
  name: 'return',
  theme: 'filled',
};

export default ReturnFilled;
