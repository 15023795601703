// GENERATE BY ./scripts/generate.ts
// DON NOT EDIT IT MANUALLY

import * as React from 'react';
import { SocialFacebookFilled as SocialFacebookFilledSvg } from 'icons-svg';
import type { IconReactProps } from '../components/IconReact';
import IconReact from '../components/IconReact';

const SocialFacebookFilled = (
  props: IconReactProps,
  ref: React.Ref<HTMLSpanElement>,
): React.ReactElement => (
  <IconReact {...props} ref={ref} icon={SocialFacebookFilledSvg} />
);

SocialFacebookFilled.displayName = 'SocialFacebookFilled';
export default React.forwardRef<HTMLSpanElement, IconReactProps>(
  SocialFacebookFilled,
);
