// This icon file is generated automatically.

import { IconDefinition } from '../types';

const RerollLockOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      fill: 'currentColor',
      viewBox: '0 0 15 12',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M8.67 11.67a6 6 0 0 1-2.14-.42 6 6 0 0 1-1.8-1.17l.73-.7q.64.6 1.45.94.81.35 1.76.35 1.94 0 3.3-1.37A4.5 4.5 0 0 0 13.33 6a4.5 4.5 0 0 0-1.36-3.3 4.5 4.5 0 0 0-3.3-1.37 4.5 4.5 0 0 0-3.3 1.37A4.5 4.5 0 0 0 4 6v.52L5.33 5.2l.7.7L3.5 8.43.96 5.89l.71-.7L3 6.54V6a5.5 5.5 0 0 1 1.66-4 5.7 5.7 0 0 1 4-1.67A5.5 5.5 0 0 1 12.68 2a5.7 5.7 0 0 1 1.66 4q0 2.37-1.65 4.02a5.5 5.5 0 0 1-4.01 1.66M7.33 8.59a.6.6 0 0 1-.44-.18.6.6 0 0 1-.18-.45v-2q0-.27.19-.45a.7.7 0 0 1 .47-.18v-.7q0-.54.38-.92t.92-.38.91.38.38.92v.7q.29 0 .48.18.18.18.19.45v2a.6.6 0 0 1-.18.45.6.6 0 0 1-.45.18zm.63-3.26h1.41v-.7q0-.3-.2-.5a.7.7 0 0 0-.5-.2q-.3 0-.5.2t-.2.5z',
        },
      },
    ],
  },
  name: 'reroll-lock',
  theme: 'outlined',
};

export default RerollLockOutlined;
