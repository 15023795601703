// This icon file is generated automatically.

import { IconDefinition } from '../types';

const BonusFilled: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 24 24',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'g',
        attrs: { 'clip-path': 'url(#clip0_2051_10425)' },
        children: [
          {
            tag: 'path',
            attrs: {
              d: 'M14.97 7.1a9.5 9.5 0 0 1 1.1-4.25.59.59 0 0 0-.78-.79l-1.96.99-.88-.88a.6.6 0 0 0-.83 0l-.87.88-1.97-.99a.59.59 0 0 0-.78.79 9.5 9.5 0 0 1 1.1 4.26l-2.94 3.56a7.6 7.6 0 0 0-1.74 4.85c0 4.18 3.36 6.48 7.62 6.48s7.61-2.3 7.61-6.48c0-1.77-.61-3.5-1.74-4.85zM10.6 4.29c.23.12.5.07.68-.1l.76-.76.76.75c.17.18.45.23.67.11l.9-.45q-.47 1.4-.56 2.9h-3.55q-.08-1.5-.56-2.9zm1.44 16.55c-2.97 0-6.45-1.4-6.45-5.31 0-1.5.53-2.96 1.48-4.1l2.9-3.52h4.14l2.9 3.51a6.5 6.5 0 0 1 1.47 4.1c0 3.93-3.47 5.32-6.44 5.32M5.3 7.98a.59.59 0 1 0-.59 1.01.59.59 0 0 0 .59-1.01',
            },
          },
          {
            tag: 'path',
            attrs: {
              d: 'M12.03 12.76c.33 0 .59.26.59.58a.59.59 0 0 0 1.17 0c0-.76-.49-1.4-1.17-1.65v-.86a.59.59 0 1 0-1.17 0v.86a1.76 1.76 0 0 0 .58 3.41.59.59 0 1 1-.58.59.59.59 0 0 0-1.18 0c0 .76.5 1.42 1.18 1.66v.51a.59.59 0 0 0 1.17 0v-.52a1.76 1.76 0 0 0-.59-3.41.59.59 0 0 1 0-1.17M20.24 6.73h-.59v-.59a.59.59 0 0 0-1.17 0v.59h-.58a.59.59 0 0 0 0 1.17h.58v.59a.59.59 0 0 0 1.17 0V7.9h.59a.59.59 0 0 0 0-1.17M4.42 4.93a.59.59 0 1 0 1.17 0v-.58h.59a.59.59 0 0 0 0-1.18h-.59V2.6a.59.59 0 1 0-1.17 0v.58h-.58a.59.59 0 0 0 0 1.18h.58z',
            },
          },
        ],
      },
    ],
  },
  name: 'bonus',
  theme: 'filled',
};

export default BonusFilled;
