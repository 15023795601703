// This icon file is generated automatically.

import { IconDefinition } from '../types';

const ProjectAFilled: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 30 30',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          'clip-rule': 'evenodd',
          d: 'M10.5.69v5.63q-.36-.07-.73.16a10 10 0 1 0 6.48-1.4c-.69-.09-1.25.48-1.25 1.17s.56 1.24 1.24 1.35A7.5 7.5 0 1 1 10.5 9v3.5a1 1 0 0 0 1 1h.5a1 1 0 0 0 1-1V.13Q13.98 0 15 0a15 15 0 1 1-4.5.69',
          'fill-rule': 'evenodd',
        },
      },
    ],
  },
  name: 'project-a',
  theme: 'filled',
};

export default ProjectAFilled;
