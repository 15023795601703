// This icon file is generated automatically.

import { IconDefinition } from '../types';

const BetTransactionOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 24 24',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M11.42 18.63h1.5v-1h1.25q.33 0 .54-.22a.7.7 0 0 0 .21-.54v-3q0-.31-.21-.53a.7.7 0 0 0-.54-.21h-3.25v-1.5h4v-1.5h-2v-1h-1.5v1h-1.25q-.32 0-.53.21a.7.7 0 0 0-.22.54v3q0 .31.22.53.21.21.53.21h3.25v1.5h-4v1.5h2zM6.31 21.5q-.75 0-1.28-.52a1.7 1.7 0 0 1-.53-1.29V4.31q0-.75.53-1.28.51-.53 1.28-.53h7.94l5.25 5.25v11.94q0 .75-.52 1.29-.53.52-1.29.52zM13.5 7.88V4H6.3a.3.3 0 0 0-.2.1.3.3 0 0 0-.1.2v15.4q0 .1.1.2t.2.1h11.4a.3.3 0 0 0 .2-.1.3.3 0 0 0 .1-.2V7.87z',
        },
      },
    ],
  },
  name: 'bet-transaction',
  theme: 'outlined',
};

export default BetTransactionOutlined;
