// This icon file is generated automatically.

import { IconDefinition } from '../types';

const AffiliateFilled: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 24 24',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M13.75 12.71q-1.05 0-1.77-.72a2.4 2.4 0 0 1-.73-1.78q0-1.05.73-1.77a2.4 2.4 0 0 1 1.77-.73q1.05 0 1.77.73.73.72.73 1.77T15.52 12t-1.77.72m-6.6 3.1q-.75 0-1.27-.53A1.7 1.7 0 0 1 5.35 14V6.42q0-.75.53-1.27.53-.54 1.27-.53h13.2q.75 0 1.27.53.54.53.53 1.27V14q0 .75-.53 1.28t-1.27.53zm1.5-1.5h10.2q0-.75.53-1.28t1.27-.53V7.92q-.75 0-1.27-.53a1.7 1.7 0 0 1-.53-1.27H8.65q0 .75-.53 1.27-.53.54-1.27.53v4.58q.75 0 1.27.53.54.53.53 1.28m10.52 5H3.65q-.75 0-1.27-.53a1.7 1.7 0 0 1-.53-1.28V7.6h1.5v9.9q0 .12.1.21.1.1.2.1h15.52zm-12.02-5h-.3V6.1h.3a.3.3 0 0 0-.21.1.3.3 0 0 0-.1.21V14q0 .13.1.22.09.09.21.09',
        },
      },
    ],
  },
  name: 'affiliate',
  theme: 'filled',
};

export default AffiliateFilled;
