// This icon file is generated automatically.

import { IconDefinition } from '../types';

const EyeInvisibleOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 16 16',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'm10.52 8.65-.75-.75q.15-.83-.48-1.49a1.6 1.6 0 0 0-1.52-.51l-.75-.75a2 2 0 0 1 .98-.2q1.14 0 1.93.79a2.6 2.6 0 0 1 .74 2.45q-.06.24-.15.46m2.12 2.07-.74-.69q.63-.48 1.13-1.05.48-.58.84-1.31a6.55 6.55 0 0 0-6.82-3.6q-.47.05-.92.2l-.77-.78A7 7 0 0 1 8 3a7.5 7.5 0 0 1 6.97 4.67 7.5 7.5 0 0 1-2.33 3.05m.53 3.86-2.7-2.68a7 7 0 0 1-2.47.43 7.56 7.56 0 0 1-6.97-4.67A7.4 7.4 0 0 1 3.27 4.7L1.42 2.83l.7-.7 11.76 11.75zm-9.2-9.19q-.53.43-1.03 1.02-.5.58-.8 1.26A6.6 6.6 0 0 0 8 11.33q.45 0 .9-.07t.77-.16l-.84-.87q-.18.08-.4.11-.22.04-.43.04-1.14 0-1.93-.79a2.6 2.6 0 0 1-.64-2.75z',
        },
      },
    ],
  },
  name: 'eye-invisible',
  theme: 'outlined',
};

export default EyeInvisibleOutlined;
