// This icon file is generated automatically.

import { IconDefinition } from '../types';

const CorrectOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 8 6',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M7.25.7a.17.17 0 0 0-.27 0l-3.9 4.21q-.06.06-.11 0L1.03 2.82a.17.17 0 0 0-.27 0 .2.2 0 0 0 0 .3L2.7 5.21q.14.14.31.14a.4.4 0 0 0 .31-.14l3.91-4.2a.23.23 0 0 0 .02-.3',
        },
      },
      {
        tag: 'path',
        attrs: {
          'clip-rule': 'evenodd',
          d: 'm7.61.4.01.02c.2.26.2.65-.05.91l-3.9 4.2a.9.9 0 0 1-1.31 0L.42 3.43a.7.7 0 0 1 0-.92c.26-.3.7-.3.96 0l1.64 1.77L6.64.39c.26-.29.7-.29.96 0zM1.03 2.83a.17.17 0 0 0-.27 0 .2.2 0 0 0 0 .3L2.7 5.21q.14.14.31.14a.4.4 0 0 0 .31-.14l3.91-4.2.03-.05a.2.2 0 0 0 0-.25.17.17 0 0 0-.28 0l-3.9 4.2-.05.03H3l-.03-.03z',
          'fill-rule': 'evenodd',
        },
      },
    ],
  },
  name: 'correct',
  theme: 'outlined',
};

export default CorrectOutlined;
