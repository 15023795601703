// This icon file is generated automatically.

import { IconDefinition } from '../types';

const CasinoFilled: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 24 24',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'm15.07 14.42 1-3.64-3.12-2.16-1 3.64zM4.3 19.06l-.63-.3q-.78-.33-1.06-1.13a2 2 0 0 1 .08-1.55l1.6-3.47zm4.19 1.82q-.82 0-1.41-.6-.6-.59-.59-1.41v-5.35l2.44 6.71.14.35q.06.15.18.3zm4.76-.54a1.8 1.8 0 0 1-1.4-.06q-.69-.3-.94-1.02L6.67 7.58q-.26-.71.06-1.4.32-.67 1.03-.93l6.96-2.55q.71-.26 1.4.06.68.31.93 1.03l4.26 11.68q.25.7-.06 1.4-.3.68-1.02.93zm-.52-1.43 6.97-2.53a.3.3 0 0 0 .18-.16.3.3 0 0 0 0-.24L15.64 4.31a.3.3 0 0 0-.16-.18.3.3 0 0 0-.23 0L8.29 6.66a.3.3 0 0 0-.18.16.3.3 0 0 0 0 .24l4.23 11.67q.06.12.16.18t.24 0',
        },
      },
    ],
  },
  name: 'casino',
  theme: 'filled',
};

export default CasinoFilled;
