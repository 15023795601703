// This icon file is generated automatically.

import { IconDefinition } from '../types';

const SocialFacebookFilled: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 30 30',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'g',
        attrs: { 'clip-path': 'url(#clip0_2051_10302)' },
        children: [
          {
            tag: 'path',
            attrs: {
              'clip-rule': 'evenodd',
              d: 'M14.95.45q4.06.05 7.33 2a14.63 14.63 0 0 1 3.67 22.15 14.8 14.8 0 0 1-8.2 4.9V19.05h2.85l.65-4.1h-4.31v-2.7c-.03-.55.15-1.1.5-1.53q.5-.66 1.81-.7h2.6V6.44a22 22 0 0 0-3.35-.29q-2.6.01-4.12 1.47-1.5 1.46-1.55 4.22v3.11H9.55v4.1h3.28V29.5c-3.7-.62-6.6-2.25-8.87-4.89a14.6 14.6 0 0 1-3.53-9.54 14.6 14.6 0 0 1 7.2-12.61 14.4 14.4 0 0 1 7.32-2',
              'fill-rule': 'evenodd',
            },
          },
        ],
      },
    ],
  },
  name: 'social-facebook',
  theme: 'filled',
};

export default SocialFacebookFilled;
