// This icon file is generated automatically.

import { IconDefinition } from '../types';

const InfoCircleOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      fill: 'currentColor',
      viewBox: '0 0 14 14',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M6.5 10.17h1V6.33h-1zM7 5.19q.23 0 .38-.15.16-.15.16-.39a.5.5 0 0 0-.16-.38.5.5 0 0 0-.38-.15.5.5 0 0 0-.38.15q-.16.15-.16.38t.16.39.38.15m0 8.14a6.2 6.2 0 0 1-4.48-1.85 6.4 6.4 0 0 1-1.35-6.95 6.4 6.4 0 0 1 3.36-3.36Q5.69.67 7 .67a6.2 6.2 0 0 1 4.48 1.85 6.4 6.4 0 0 1 1.35 6.95 6.4 6.4 0 0 1-3.36 3.36q-1.16.5-2.47.5m0-1q2.24 0 3.78-1.55A5.2 5.2 0 0 0 12.33 7q0-2.24-1.55-3.78A5.2 5.2 0 0 0 7 1.67q-2.24 0-3.78 1.55A5.2 5.2 0 0 0 1.67 7q0 2.24 1.55 3.78A5.2 5.2 0 0 0 7 12.33',
        },
      },
    ],
  },
  name: 'info-circle',
  theme: 'outlined',
};

export default InfoCircleOutlined;
